<template>
  <div class="calendar">
    <div class="calendar-container" :class="{'hide-scroll-bar': isMobile, 'kb-width-max': isMobile}">
      <div class="calendar-wrap">
        <!-- [!dev] day-today -->
        <button class="kb-btn-nav kb-btn-kb-nav-prev" @click="moveWeek(false)"><i class="icon-arrow-prev"></i></button>
        <div v-for="(date, idx) in targetWeek" class="day" :class="{'day-today': (date.year === selectedDate.year && date.month === selectedDate.month && date.day === selectedDate.day ), 'day-disabled': idx === 0 || idx === 6}" :key="idx">
          <a href="javascript:" class="day-link" @click="selectDate(date, true)">
            <span class="date">{{ date.day }}</span>

            <span v-if="(date.year === today.year && date.month === today.month && date.day === today.day )" class="text">오늘</span>
            <span v-else class="text">{{ getWeekOfDay(idx) }}</span>
          </a>
        </div>
        <button class="kb-btn-nav kb-btn-kb-nav-next" @click="moveWeek(true)"><i class="icon-arrow-next"></i></button>
      </div>

<!--      <div class="calendar-nav">-->
<!--        <button class="kb-btn-nav kb-btn-kb-nav-prev" @click="moveWeek(false)"><i class="icon-arrow-prev"></i></button>-->
<!--        <button class="kb-btn-nav kb-btn-kb-nav-next" @click="moveWeek(true)"><i class="icon-arrow-next"></i></button>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

import {computed, reactive} from 'vue';
import {getTargetDate, getWeek, getWeekOfDay} from '@/assets/js/util';
import {useStore} from 'vuex';

export default {
  name: 'TrainWeekCalendar',
  props: {
    isLoading: Boolean,
    dayFunc: Function,
  },
  setup(props){
    const store = useStore();
    const isMobile = computed(() => store.state.auth.isMobile);

    const currentDate = new Date();

    const today = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate()
    });

    const selectedDate = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate()
    });

    const targetDate = reactive({
      year: currentDate.getFullYear(),
      month: (currentDate.getMonth() + 1),
      day: currentDate.getDate()
    });

    const targetWeek = computed(() => getWeek(targetDate.year, targetDate.month, targetDate.day));

    const diffWeek = 1000 * 60 * 60 * 24 * 7;

    const moveWeek = (isNext) => {
      if (props.isLoading) return;
      const tDate = getTargetDate(targetDate.year, targetDate.month, targetDate.day);
      const resultDate = new Date(isNext ? tDate.getTime() + diffWeek : tDate.getTime() - diffWeek);
      selectDate({year: resultDate.getFullYear(), month: (resultDate.getMonth() + 1), day: (resultDate.getDate())});
    }

    const selectDate = ({year, month, day}, isSelect) => {
      if (props.isLoading) return;
      targetDate.year = year;
      targetDate.month = month;
      targetDate.day = day;

      if(isSelect){
        selectedDate.year = year;
        selectedDate.month = month;
        selectedDate.day = day;
        if(props.dayFunc instanceof Function) props.dayFunc(year, month, day);
      }
    }

    return {
      isMobile,
      today,
      targetDate,
      targetWeek,
      selectedDate,

      moveWeek,
      selectDate,
      getWeekOfDay
    }


  }
}
</script>
